import React from 'react'

function NotFound({staticContext}) {
  if (staticContext) {
    staticContext.status = 404;
  }
  return(
    <div>
      404
    </div>
  );
}

export default NotFound;
